import { UseQueryOptions, useQuery } from 'react-query'
import axios from 'axios'
import { IPatient } from '@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IPatient'

type Queryoptions = Omit<UseQueryOptions<IPatient, unknown, IPatient , string[]>, 'queryKey' | 'queryFn'>
const apiPath = `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/api`
export const searchFhirResource = async (resourceType: string, filters: string) => {
	try {
		const req = {
			resourceType: resourceType,
			filters: filters,
		}

		const res = await axios.post(`${apiPath}/fhir/findResources`, req)
		return res.data[0].resource
	} catch(err) {
		console.error(err)
	}
}
export const useAdvancedSearchFhirPatient = (
	resourceType: string,
	filters: string,
	options?: Queryoptions,
) => {
	return useQuery(
		['FhirSearch', resourceType],
		async () => {
			try {
				const res = await searchFhirResource(resourceType, filters)
				return res
			} catch (error) {
				console.error(error)
				throw new Error(error as string)
			}
		},
		options,
	)
}
