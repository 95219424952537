import { auth } from '@/config/DBConnection'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { IPayment, countries, serviceTypes } from '@umahealth/entities'
import axios from 'axios'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'

interface IGetPayment {
	country: countries,
	service: serviceTypes,
	paymentId: string,
	uid: string,
}

type QueryOptions = Omit<UseQueryOptions<IPayment, unknown, IPayment, QueryKey>, 'queryKey' | 'queryFn'>

export function useGetPayment ({ country, service, paymentId, uid }: IGetPayment, options?: QueryOptions) {
	return useQuery<IPayment>({
		queryKey: ['MegalithQuery', 'useGetPayment'],
		queryFn: async () => {
			const currentUser = auth?.currentUser
			const token = await currentUser?.getIdToken()

			const headers = {
				Authorization: `Bearer ${token}`,
				'Content-type': 'Application/json',
				country,
				uid,
			}

			const res = await axios.get<IPayment>(`${UMA_BACKEND_URL}/payment/${service}/${paymentId}`, {headers})
			return res.data
		},
		...options,
	})
}