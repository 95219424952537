import React from 'react'
import { IHistorySections } from '@umahealth/entities'
import { Icon, IconsNames } from 'occipital-new'
import type { UseQueryResult } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { Text } from 'occipital-new'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import style from '../../HomePage/styles/homepage.module.scss'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import { useRouter } from 'next/navigation'
import { BundleEntry } from '@smile-cdr/fhirts/dist/FHIR-R3'

interface EventElement {
	queryEvent: UseQueryResult<BundleEntry[], unknown>
	section: IHistorySections
	fhirPatientId: string
}

function FhirEventElement({
	queryEvent,
	section,
	fhirPatientId
}: EventElement): JSX.Element | null {
	const notifications = useAppSelector(
		(state) => state.front.notificationsHome,
	)
	const track = useTrack()
	const router = useRouter()

	if (queryEvent.isLoading) {
		return (
			<div
				className="historyCard__eventSection"
				onClick={() => router.push(section.link)}
			>
				<Skeleton width={20} height={20} circle />
				<Skeleton className="historyCard__event" width="136px" />
			</div>
		)
	}

	function redirectToEventHistory(route: string) {
		track.mutate({
			eventInput: `[Home] Clic en historial farmacovigilancia ${route}`,
		})
		router.push(route)
	}

	if (queryEvent.isSuccess && queryEvent?.data?.length > 0) {
		return (
			<React.Fragment key={section.title}>
				<button
					className="historyCard__eventSection"
					id={section.title}
					onClick={() =>
						redirectToEventHistory(`/encounters/${fhirPatientId}`)
					}
				>
					<div className={style.historyCard}>
						<Icon
							color="primary"
							name={`${section.icon as IconsNames}`}
							size={'m'}
						/>
						<h2 className="historyCard__event">{section.title}</h2>
					</div>
					{section.title === 'Recetas' &&
						notifications.prescriptions.new && (
						<Text
							size="xxs"
							weight="semibold"
							color="background-light"
							tag="span"
							className={style.notifications}
						>
							{notifications.prescriptions.quantity}{' '}
							{notifications.prescriptions.quantity === 1
								? 'receta nueva'
								: 'recetas nuevas'}
						</Text>
					)}
					{section.title === 'Consultas' &&
						notifications.consultations.new && (
						<Text
							size="xxs"
							weight="semibold"
							color="background-light"
							tag="span"
							className={style.notifications}
						>
							{notifications.consultations.quantity}{' '}
							{notifications.consultations.quantity === 1
								? 'consulta nueva'
								: 'consultas nuevas'}
						</Text>
					)}
				</button>
			</React.Fragment>
		)
	}

	return null
}

export default FhirEventElement
