import { useMutation } from 'react-query'

/**
 * 
 * @param options más informacion aquí: https://developers.google.com/tag-platform/tag-manager/web/datalayer
 * @returns Un objeto con la función mutate que permite pushear el dataLayer
 */
function useDatalayerAsync( options : Record<string, unknown> ){
	
	const pushFunction = () => {
		if (typeof window != 'undefined')
			window.dataLayer.push(options)
	}

	return {
		push: pushFunction
	}

}

export function useDatalayerMutation(){
	return useMutation(['datalayer'],async(data : Record<string, unknown>) => {
		if (typeof window != 'undefined')
			window.dataLayer.push(data)
	})
}

export default useDatalayerAsync