'use client'
import { UMA_BACKEND_URL } from '@/config/endpoints'

interface IDeletePatientSubscriptionPending {
	token: string | undefined
	documentId: string
}

export async function deletePatientSubscriptionPending({ token, documentId }: IDeletePatientSubscriptionPending) {
	if (!token) {
		console.error(
			'Se intentó borrar la suscripción en pendiente sin autorización',
		)
		return null
	}

	const result = await fetch(`${UMA_BACKEND_URL}/subscriptions/pending/${documentId}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		cache: 'no-store',
		next: {
			tags: ['subscriptions'],
		},
	})

	if (!result.ok) {
		console.error(
			`Error deleting pending subscription: ${result.status}, ${result.statusText}`,
		)
		throw new Error(
			`Error deleting pending subscription: ${result.status}, ${result.statusText}`,
		)
	}

	const data = await result.json()
	return data
}
