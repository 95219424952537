import { Button, Paragraph } from '@umahealth/occipital'
import { useEffect } from 'react'

interface IThankYouMsg {
	buttonText: string,
	messageDescription: string,
	messageHeader: string,
	closeSurvey: () => void,
	closeDelay?: number,
}

function ThankYouMessage({ buttonText, messageDescription, messageHeader, closeSurvey, closeDelay }: IThankYouMsg) {
	useEffect(() => {
		if (closeDelay) {
			setTimeout(closeSurvey, closeDelay)
		}
	}, [closeDelay])

	return (
		<div className='flex flex-col gap-1.5 mt-1 text-center'>
			<Paragraph className='!font-semibold text-base'>
				{messageHeader}
			</Paragraph>
			<Paragraph className='!font-regular text-sm'>
				{messageDescription}
			</Paragraph>
			<Button
				type='button'
				variant='filled'
				className='w-full mt-7 !font-bold'
				onClick={closeSurvey}
			>
				{buttonText}
			</Button>
		</div>
	)
}

export default ThankYouMessage