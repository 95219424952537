import classNames from 'classnames'
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form'
import { INpsSurveyForm } from '../NpsSurvey'
import { SurveyAppearance } from 'posthog-js'

interface IScoreButton {
	appearance: SurveyAppearance | null,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	score: string,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
}

function ScoreButton({ appearance, fields, score, update}: IScoreButton) {
	return (
		<button
			className={classNames(
				'py-2 text-base font-semibold border-solid',
				score === '10' ? 'border-r-0' : 'border-r',
				fields[0].value === score ? 'text-white' : '',
			)}
			style={{
				borderColor: appearance?.borderColor ?? '#c9c6c6',
				backgroundColor: (fields[0].value === score)
					? appearance?.ratingButtonActiveColor ?? 'black'
					: appearance?.ratingButtonColor ?? 'white'
			}}
			type='button'
			onClick={() => update(0, { value: score })}
		>
			{score}
		</button>
	)
}

export default ScoreButton