import { Survey } from 'posthog-js'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { PostHog } from 'posthog-js/react'
import { Icon } from '@umahealth/occipital/client'
import NpsQuestions from './components/NpsQuestions'
import ThankYouMessage from './components/ThankYouMessage'
import classNames from 'classnames'
import moment from 'moment'

interface INpsSurvey {
	survey: Survey
	posthog: PostHog
}

export type QuestionResponse = {
	value: string | string[]
}

export interface INpsSurveyForm {
	surveyResponse: QuestionResponse[]
}

function NpsSurvey({ survey, posthog }: INpsSurvey) {
	const {
		control,
		register,
		handleSubmit,
	} = useForm<INpsSurveyForm>({
		defaultValues: {
			surveyResponse: [{
				value: ''
			}]
		}
	})
	const { fields, update, append } = useFieldArray({
		name: 'surveyResponse',
		control,
	})
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
	const [show, setShow] = useState(false)
	const question = survey.questions[currentQuestionIndex]
	const appearance = survey.appearance
	/** If the index of the current question is equal to -1 &
	 * the survey is configured to display a 'thank you message', this would be true */
	const showDisplayMessage = (currentQuestionIndex === -1) && appearance?.displayThankYouMessage

	const onSubmit: SubmitHandler<INpsSurveyForm> = (formValues) => {
		const parsedValue = currentQuestionIndex === 0
			? Number(formValues.surveyResponse[currentQuestionIndex]?.value)
			: String(formValues.surveyResponse[currentQuestionIndex]?.value)

		/** Will be the number of the next question in the survey flow. If there is no next question it will be 'end' */
		const nextQuestion = posthog?.getNextSurveyStep(survey, currentQuestionIndex, parsedValue)

		/** We make sure there is an upcoming question */
		if (typeof nextQuestion === 'number') {
			return setCurrentQuestionIndex(nextQuestion)
		}

		/** If not, send survey and set current question to -1 in order to show the 'thankYouMessage' */
		sendSurvey(formValues)
		return setCurrentQuestionIndex(-1)
	}

	const sendSurvey = (formValues: INpsSurveyForm) => {
		// Enviar el evento solo en produccion
		if (process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === 'uma-v2'){
			posthog?.capture('survey sent', {
				$survey_id: survey.id,
				$survey_response: formValues.surveyResponse[0]?.value,
				$survey_response_1: formValues.surveyResponse[1]?.value || '',
				$survey_response_2: formValues.surveyResponse[2]?.value || '',
				$survey_response_3: formValues.surveyResponse[3]?.value || '',
				$survey_response_4: formValues.surveyResponse[4]?.value || '',
			})
		}
		localStorage.setItem('posthog.surveyStatus', 'sent')
	}

	const dismissSurvey = () => {
		setShow(false)
		posthog?.capture('survey dismissed', {
			$survey_id: survey.id,
		})
		localStorage.setItem('posthog.surveyStatus', 'dismissed')
	}

	const showSurvey = () => {
		const surveySeen = localStorage.getItem('posthog.surveyStatus')
		const surveySeenLastTime = localStorage.getItem('posthog.surveySeenLastTime')
		const lastTimeSeenMoment = moment(surveySeenLastTime, 'DD-MM-YYYY HH:mm:ss')
		const nowMoment = moment()
		const past30Days = nowMoment.diff(lastTimeSeenMoment, 'days') >= 30
		const past3Days = nowMoment.diff(lastTimeSeenMoment, 'days') >= 3

		if ((
		// Si nunca se mostro la encuesta, o si no la respondio, o si pasaron 30 dias desde la ultima vez, la mostramos
			surveySeen === 'shown' ||
				past30Days ||
				!surveySeenLastTime
		) || (
		// Si rechazo la encuesta se la volvemos a mostrar a los 3 dias
			surveySeen === 'dismissed' &&
				past3Days
		)) {
			setShow(true)
			posthog?.capture('survey shown', {
				$survey_id: survey.id,
			})
			localStorage.setItem('posthog.surveyStatus', 'shown')
			localStorage.setItem('posthog.surveySeenLastTime', moment().format('DD-MM-YYYY HH:mm:ss'))
		}
	}

	useEffect(() => {
		setTimeout(showSurvey, (appearance?.surveyPopupDelaySeconds ?? 0) * 1000)
	}, [])

	return (
		<div
			className={classNames(
				'w-[286px] fixed right-[18px] bottom-0 border border-solid rounded-[10px] z-[101]',
				!show ? 'hidden' : 'flex'
			)}
			style={{
				borderColor: appearance?.borderColor ?? '#c9c6c6',
				backgroundColor: appearance?.backgroundColor ?? '#eeeded'
			}}
		>
			<form
				className='flex flex-col relative px-6 py-5 w-full'
				onSubmit={handleSubmit(onSubmit)}
			>
				<button
					type='button'
					className='w-9 h-9 rounded-full flex justify-center items-center absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 border-[1.5px] border-solid'
					style={{
						borderColor: appearance?.borderColor ?? '#c9c6c6',
						backgroundColor: appearance?.backgroundColor ?? '#eeeded',
					}}
					onClick={dismissSurvey}
				>
					<Icon name='close' />
				</button>
				{showDisplayMessage ? (
					<ThankYouMessage
						buttonText={appearance.thankYouMessageCloseButtonText ?? ''}
						closeSurvey={() => setShow(false)}
						closeDelay={appearance.autoDisappear ? 3000 : 0}
						messageDescription={appearance?.thankYouMessageDescription ?? ''}
						messageHeader={appearance?.thankYouMessageHeader ?? ''}
					/>
				) : (
					<NpsQuestions
						update={update}
						fields={fields}
						question={question}
						questionIndex={currentQuestionIndex}
						register={register}
						append={append}
						appearance={appearance}
					/>
				)}
			</form>
		</div>
	)
}

export default NpsSurvey