import { SurveyAppearance, SurveyQuestion } from 'posthog-js'
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate, UseFormRegister } from 'react-hook-form'
import { INpsSurveyForm } from '../NpsSurvey'
import RatingQuestion from './RatingQuestion'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import OpenQuestion from './OpenQuestion'

interface IQuestion {
	appearance: SurveyAppearance | null,
	append: UseFieldArrayAppend<INpsSurveyForm, 'surveyResponse'>,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	question: SurveyQuestion,
	questionIndex: number,
	register: UseFormRegister<INpsSurveyForm>,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
}

/** Returns the component corresponding to each type of question. */
function Question({
	appearance,
	append,
	fields,
	question,
	questionIndex,
	register,
	update,
}: IQuestion) {
	if (question.type === 'rating') {
		return (
			<RatingQuestion
				appearance={appearance}
				fields={fields}
				question={question}
				update={update}
			/>
		)
	}

	if (question.type === 'multiple_choice') {
		return (
			<MultipleChoiceQuestion
				append={append}
				fields={fields}
				question={question}
				questionIndex={questionIndex}
				update={update}
			/>
		)
	}

	if (question.type === 'open') {
		return (
			<OpenQuestion
				appearance={appearance}
				question={question}
				register={register}
			/>
		)
	}
}

export default Question