import { ISpecialistParams, ISpecialistParams_calendar, ISpecialistParams_confirm, ISpecialistParams_confirmPrescription, ISpecialistParams_default, ISpecialistParams_done, ISpecialistParams_marketplace, ISpecialistParams_prescriptionRequest, ISpecialistParams_reasons, ISpecialistParams_selectAttType, ISpecialistParams_SelectSpecialtie } from './ISpecialistParams'
import { MarketplaceSteps } from './MarketplaceSteps'

function makeSpecialistPath( nextStep: 'calendar', data: Omit<ISpecialistParams_calendar, 'step'>) : string;
function makeSpecialistPath( nextStep: 'marketplace', data: Omit<ISpecialistParams_marketplace, 'step'>) : string;
function makeSpecialistPath( nextStep: 'SelectSpecialtie', data: Omit<ISpecialistParams_SelectSpecialtie, 'step'>) : string;
function makeSpecialistPath( nextStep: 'selectCorporate', data: Omit<ISpecialistParams_SelectSpecialtie, 'step'>) : string;
function makeSpecialistPath( nextStep: 'reasons', data: Omit<ISpecialistParams_reasons, 'step'>) : string;
function makeSpecialistPath( nextStep: 'confirm', data: Omit<ISpecialistParams_confirm, 'step'>) : string;
function makeSpecialistPath( nextStep: 'mercadopagoResult', data: Omit<ISpecialistParams_confirm, 'step'>) : string;
function makeSpecialistPath( nextStep: 'done', data: Omit<ISpecialistParams_done, 'step'>) : string;
function makeSpecialistPath( nextStep: 'pending', data: Omit<ISpecialistParams_default, 'step'>) : string;
function makeSpecialistPath( nextStep: 'profile', data: Omit<ISpecialistParams_calendar, 'step'>) : string;
function makeSpecialistPath( nextStep: 'selectAttType', data: Omit<ISpecialistParams_selectAttType, 'step'>) : string;
function makeSpecialistPath( nextStep: 'prescriptionRequest', data: Omit<ISpecialistParams_prescriptionRequest, 'step'>) : string;
function makeSpecialistPath( nextStep: 'confirmPrescription', data: Omit<ISpecialistParams_confirmPrescription, 'step'>) : string;

function makeSpecialistPath( nextStep : MarketplaceSteps, data : Omit<ISpecialistParams,'step'>) {

	if (nextStep === 'selectCorporate') {
		if (data.type === 'consultorio') {
			return `/consultorio/${data.patientUid}?type=${data.type}`
		}
		return `/marketplace/${data.patientUid}?type=${data.type}`
	}

	let path = '/marketplace?'
	for (const [key, value] of Object.entries(data)) { 
		if (key != 'step' && value != undefined) {
			path = path + `${key}=${value}&`
		}
	}

	path = path + `step=${nextStep}`

	return path
}

export default makeSpecialistPath
