export type ClientsNames = 'UMA' | 'IOMA' | 'OSPRERA' | 'IOMA-UMA' | 'POLICIA FEDERAL' | 'VIVO' | 'FARMATODO' | 'UNION PERSONAL';
type LoginMethods = 'platform'|'customToken'
type ColorThemes = 'UMA_THEME'|'IOMA_THEME' | 'FARMATODO_THEME'
type LogoNames = 'UMA_LOGO' | 'IOMA_LOGO' | 'FARMATODO_LOGO'

interface ContactInfo {
	email: string,
	callCenter: string,
	address: string
}
/** 
 * Esta interfaz nos sirve para localizar que cosas quiere el cliente de nuestra app,
 * Como un esquema de colores propio o un logo propio, tambien para entender que tipo
 * de metodo de login usa por ejemplo
*/

export interface IClientState {
	/** Nombre del cliente, si es IOMA-UMA, significa que se conecta desde la app de UMA y no desde la app de IOMA */
	client: ClientsNames,
	/** Metodo de logeo del cliente, puede ser por la plataforma o con un customToken*/
	login_method: LoginMethods,
	/** Tema del color del cliente o nuestro */
	color_theme: ColorThemes,
	/** Logo del cliente o nuestro */
	logo_name: LogoNames
	/** Si el cliente acepta dependants o no */
	dependants: boolean
	/** Si el cliente puede añadir dependants */
	addDependants: boolean
	/** Si el cliente tiene especialistas gratis*/
	freeSpecialists: boolean
	/** Si el cliente tiene hisopados gratis */
	freeSwabTest: boolean
	/** Si el cliente ingresó desde una app nativa de android o ios, ejemplo, app de IOMA */
	needMobilePermissions: boolean
	/**Información de contacto del cliente, en caso de que no sea personalizado, será la info de UMA */
	contact: ContactInfo
	/** Si el cliente puede cerrar la sesión */
	canCloseSession: boolean
	/** Si el cliente puede acceder al perfil */
	profileEnabled: boolean
	/** Si el cliente puede usar chatAtt */
	canUseChatAttention: boolean
}

/** La coverage IOMA-UMA no existe, es IOMA, para que podamos usar el valor del corporate norm con los de clients, use este middleware entre ambos.
 * Por otro lado la coverage IOMA-APP refiere al cliente IOMA.
 */
export const iomaNormalize = (corporate_norm : string) => {

	if (corporate_norm === 'IOMA') { return 'IOMA-UMA' }

	if (corporate_norm === 'IOMA-APP') { return 'IOMA' }

	return corporate_norm

}

export const clients : Record<ClientsNames, IClientState> = {
	'IOMA' : {
		client: 'IOMA',
		dependants: true,
		login_method: 'customToken',
		color_theme: 'IOMA_THEME',
		logo_name: 'IOMA_LOGO',
		addDependants: false,
		freeSpecialists: true,
		freeSwabTest: true,
		/** mobile permissions o sea permissions de android/apple */
		needMobilePermissions: true,
		canCloseSession: true,
		canUseChatAttention: false,
		contact: {
			email: 'asistencia_remota@ioma.gba.gov.ar',
			callCenter: ' Call center: 0810 – 999 – 4662. ',
			address: 'IOMA Central: C. 46 Nº 886, La Plata, Provincia de Buenos Aires.'
		},
		profileEnabled: false
	},
	'OSPRERA': {
		client: 'OSPRERA',
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		dependants: false,
		addDependants: false,
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: false,
		canCloseSession: true,
		canUseChatAttention: false,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'POLICIA FEDERAL': {
		client: 'POLICIA FEDERAL',
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		dependants: true,
		addDependants: false,
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: false,
		canCloseSession: true,
		canUseChatAttention: false,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'IOMA-UMA': {
		client: 'IOMA-UMA',
		dependants: true,
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		addDependants: false,
		freeSpecialists: false,
		freeSwabTest: true,
		needMobilePermissions: false,
		canCloseSession: true,
		canUseChatAttention: false,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'VIVO': {
        	client: 'VIVO',
		dependants: true,
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		addDependants: false,
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: false,
		canCloseSession: true,
		canUseChatAttention: true,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'FARMATODO': {
		client: 'FARMATODO',
		dependants: false,
		login_method: 'platform',
		color_theme: 'FARMATODO_THEME',
		logo_name: 'FARMATODO_LOGO',
		addDependants: false,
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: false,
		canCloseSession: false,
		canUseChatAttention: false,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'UNION PERSONAL': {
		client: 'UMA',
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		dependants: true,
		addDependants: true, 
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: true,
		canCloseSession: true,
		canUseChatAttention: true,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	},
	'UMA': {
		client: 'UMA',
		login_method: 'platform',
		color_theme: 'UMA_THEME',
		logo_name: 'UMA_LOGO',
		dependants: true,
		addDependants: true, 
		freeSpecialists: false,
		freeSwabTest: false,
		needMobilePermissions: false,
		canCloseSession: true,
		canUseChatAttention: true,
		contact: {
			email: 'info@uma-health.com',
			callCenter: '',
			address: ''
		},
		profileEnabled: true
	}
}