import React, { useEffect, useState } from 'react'
import { useCollectionEvent } from '@umahealth/fe-firebase'
import { firestore } from '@/config/DBConnection'
import useContextUid from '@/services/firebase/useContextUid'
import { useMedicalRecords } from '@umahealth/fe-firebase'
import { IHistorySections } from '@umahealth/entities'
import EventElement from './EventElement'
import FhirEventElement from './FhirEventElement'
import { useAdvancedSearchFhirResource } from '@/services/requests/fhir/useAdvancedSearchFhirResource'
import { useGetPatientId } from '@/services/requests/fhir/useGetPatientId'
import { useRouter } from 'next/navigation'
import { errorHandler } from '@/config/ErrorBoundary'
import { isFarmatodo } from '@/utils/validations/ValidateCoverage'
import { useClientContext } from '@/OldRouter/Providers/ProviderHooks'
import { useUpdateFhirResource } from '@/services/requests/fhir/useUpdateResource'
import { useAdvancedSearchFhirPatient } from '@/services/requests/fhir/useAdvancedSearchFhirPatient'
import { Title } from '@umahealth/occipital'
import { Icon } from '@umahealth/occipital/client'


interface IEvents {
	sections: IHistorySections[]
}

type IHistorySectionsObject = Partial<
Record<
| 'Recetas'
| 'Consultas'
| 'Diagnóstico asistido'
| 'Historial de servicios'
| 'Derivaciones',
IHistorySections
>
>

/** esta bien utilizar any en este caso */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isArrayConElementos(arr : Array<any>|undefined) {
	// Comprueba si 'arr' es un array y tiene más de 0 elementos
	return Array.isArray(arr) && arr.length > 0
}


const Events = ({ sections }: IEvents) => {
	const [subject, setSubject] = useState('')
	const router = useRouter()
	const uid = useContextUid()
	const client = useClientContext()
	const fhirId = useGetPatientId(uid || 'NO', {enabled: !!uid && isFarmatodo(client)})
	const patientFhirData = useAdvancedSearchFhirPatient('Patient', `_id=${fhirId.data}`, {enabled: !!fhirId.data})
	const updatePatient = useUpdateFhirResource()
	const isFarmatodoClient = isFarmatodo(client)
	const EXTERNAL_URL = 'https://umasalud.com/farmatodo/fhir/extensions/place_of_registration'
	/** Define el lugar desde donde se loguea la cuenta, en este caso, mobile porque se logueo desde el celular */
	const updateCaptados = async() => {
		if(patientFhirData?.data && (
			!patientFhirData.data.extension
			|| !patientFhirData.data.extension.find((ext) => ext?.url === EXTERNAL_URL))) {
			updatePatient.mutate({
				resourceType: 'Patient',
				resourceId: patientFhirData.data.id || fhirId.data || '',
				resourcePayload: {
					...patientFhirData.data,
					extension: [
						{
							url: EXTERNAL_URL,
							valueString: 'mobile'
						}
					]
				}
			})
		}
	}

	useEffect(() => {
		if (isFarmatodoClient || patientFhirData.data) {
			updateCaptados()
		}
	}, [patientFhirData.data, fhirId.data])

	const consultas = useMedicalRecords(
		firestore,
		uid ?? 'NO',
		{},
		{
			enabled: !!uid,
		},
	)

	const historialDeServicios = useCollectionEvent(
		firestore,
		'analysis',
		uid ?? 'NO',
		process.env.NEXT_PUBLIC_COUNTRY,
		{},
		{
			enabled: !!uid,
		},
	)
	const encounters = useAdvancedSearchFhirResource(
		'Encounter',
		`subject=${subject}&_sort=-_lastUpdated`,
		{
			enabled: !!subject && isFarmatodo(client),
		},
	)

	const sectionsObject = sections.reduce<IHistorySectionsObject>(
		(accumulator, currentValue) => ({
			...accumulator,
			[currentValue.title]: currentValue,
		}),
		{} as IHistorySectionsObject,
	)
	const hasHistory = isArrayConElementos(consultas.data) || isArrayConElementos(historialDeServicios.data) || isArrayConElementos(encounters.data)

	const LoadingSucess = ( consultas.isSuccess && historialDeServicios.isSuccess && encounters.isSuccess )
	const hasDerivations =
		consultas.isSuccess && consultas.data
			? consultas.data?.some(
				(record) =>
					(record?.mr?.destino_final as string) ===
						'Indico seguimiento con especialista',
			)
			: false

	useEffect(() => {
	  if (fhirId.data){
			setSubject(`Patient/${fhirId.data}`)
	  }
	}, [fhirId.status])

	useEffect(() => {
		if (fhirId.isError && isFarmatodoClient) {
			errorHandler?.report(`Error obteniendo fhirId de usuario: ${uid} ${fhirId?.error}`)
			router.push('/pharmacovigilance/error/fhirPersonId404')
		}
	}, [fhirId.isError])

	if (!hasHistory && LoadingSucess){
		return <ul>
			<li className='flex bg-grey-6 py-5 px-4 border border-solid border-grey-5 rounded-b-md w-full'>
				<Icon className='mr-2' color="text-primary" name="info" size="size-5" />
				<Title hierarchy='h2'>
								Aún no hay registros en tu historial
				</Title>
			</li>
		</ul>
	}

	return (
		<ul>
			{sectionsObject['Consultas']?.active && (
				<EventElement
					queryEvent={consultas}
					section={{
						...sectionsObject['Consultas'],
						title: 'Historia clínica digital',
					}}
				/>
			)}
			{sectionsObject['Historial de servicios']?.active && (
				<EventElement
					queryEvent={historialDeServicios}
					section={sectionsObject['Historial de servicios']}
				/>
			)}
			{sectionsObject['Derivaciones']?.active && hasDerivations && (
				<EventElement
					queryEvent={consultas}
					section={sectionsObject['Derivaciones']}
				/>
			)}
			{(sectionsObject['Farmacovigilancia']?.active)&& (
				<FhirEventElement
					queryEvent={encounters}
					section={sectionsObject['Farmacovigilancia']}
					fhirPatientId={subject.split('/')[1]}
				/>
			)}
		</ul>
	)
}

export default Events
