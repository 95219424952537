import { useMutation } from 'react-query'
import axios from 'axios'
import { IfhirR4 } from '@smile-cdr/fhirts'

const apiPath = `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/api`
export const updateFhirResource = async (resourceType: string, resourceId: string, resourcePayload: Partial<IfhirR4.IPatient>) => {
	try {
		const req = {
			resourceType,
			resourceId,
			resourcePayload
		}

		const res = await axios.post(`${apiPath}/fhir/updateResource`, req)
		return res.data
	} catch(err) {
		console.error(err)
	}
}
export const useUpdateFhirResource = () => {
	interface IData{
		resourceType: string,
		resourceId: string,
		resourcePayload: Partial<IfhirR4.IPatient>,}
	const mutator =  useMutation(['FhirUpdate'],
		async ({resourceType, resourceId, resourcePayload}: IData) => {
			try {
				const res = await updateFhirResource(resourceType, resourceId, resourcePayload)
				return res
			} catch (error) {
				console.error(error)
				throw new Error(error as string)
			}
		},
	)
	return mutator
}
