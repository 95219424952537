'use client'
import { trackEventRegretButton } from '@/events/homeEvents'
import { Button } from '@umahealth/occipital'

function RegretButton() {

	return (
		<Button className='mt-12 w-[64%] mx-auto' size='full' href='/regret' variant='outlined' type='link' onClick={() => trackEventRegretButton()}>
			Boton de arrepentimiento
		</Button> 
	)

}

export default RegretButton