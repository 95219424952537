import { cn } from '@/lib/utils'
import { Paragraph } from '@umahealth/occipital'
import { Icon, IIcon } from '@umahealth/occipital/client'
import { Url } from 'next/dist/shared/lib/router/router'
import Link from 'next/link'

export default function ServiceButton({href, name, children, className} : {href: Url, name: IIcon['name'], children: string, className: string}){
	return <Link href={href} className={cn('w-[107px] h-[77px] border border-solid border-grey-5 text-primary rounded-md flex items-center flex-col align-middle content-center justify-center py-3 px-4 ', className)}>
		<Icon size='size-6' name={name}/>
		<Paragraph className='text-center' size='text-xxs'>
			{children}
		</Paragraph>
	</Link>
}