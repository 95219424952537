'use client'
import { Button, Image, Paragraph, Title } from '@umahealth/occipital'
import Navigation from '@/assets/illustrations/Navigation.svg'

const countries = {
	'AR': 'Argentina',
	'MX': 'México',
	'VE': 'Venezuela',
	'CO': 'Colombia'
}

// ingresaste a ÜMA MX	
// Si recidis en Argentina, te recomendamos cambiar a la web de UMA AR
// Cambiar a la web de ÜMA AR?
export default function CountryModal({patientCountry, serverCountry} : {patientCountry: string, serverCountry: string}){

	if (patientCountry === 'AR'){
		return <div className='flex flex-col items-center p-4 h-[calc(100%-40px)] gap-[1%] justify-evenly'> 
			<Image className='w-10/12' width={400} height={400} src={Navigation} alt="" />
			<Title hierarchy='h1' color='text-grey-2' weight='font-bold' size='text-m'> Ingresaste a ÜMA {serverCountry} </Title>
			<Paragraph color='text-grey-2' className='text-center px-2' weight='font-semibold' size='text-m'>Si recidís en  {countries[patientCountry]} sólo podes usar la web de {countries[patientCountry]} </Paragraph>
			<div className='Bottom m-0 relative flex flex-col gap-[1em]'>
				<Button variant='filled' type='link' onClick={() => {
					window.localStorage.setItem('selectedCountry', patientCountry)
				}} href={{
					pathname: '/home',
					search:`country=${patientCountry}`
				}}> Cambiar a ÜMA  {countries[patientCountry]}  </Button>
			</div>
		</div>
	}

	return <div className='flex flex-col items-center p-4 h-[calc(100%-40px)] gap-[1%] justify-evenly'> 
		<Image className='w-10/12' width={400} height={400} src={Navigation} alt="" />
		<Title hierarchy='h1' color='text-grey-2' weight='font-bold' size='text-m'> Ingresaste a ÜMA {serverCountry} </Title>
		<Paragraph color='text-grey-2' className='text-center px-2' weight='font-semibold' size='text-m'>Si recidís en  {countries[patientCountry]} te recomendamos cambiar a la web de  {countries[patientCountry]} </Paragraph>
		<div className='Bottom m-0 relative flex flex-col gap-[1em]'>
			<Paragraph color='text-grey-2' weight='font-bold' size='text-m'>
				¿Qué deseas hacer?
			</Paragraph>
			<Button variant='filled' type='link' onClick={() => {
				window.localStorage.setItem('selectedCountry', patientCountry)
			}} href={{
				pathname: '/home',
				search:`country=${patientCountry}`
			}}> Cambiar a ÜMA  {countries[patientCountry]}  </Button>
			<Button variant='text' type='link' onClick={() => {
				window.localStorage.setItem('selectedCountry', serverCountry)
			}} href={{
				pathname: '/home',
				search:`country=${serverCountry}`
			}}> Continuar con ÜMA  {countries[serverCountry]} </Button>
		</div>
	</div>
}