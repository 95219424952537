import { Icon } from '@umahealth/occipital/client'
import styles from '../NpsSurvey.module.scss'
import classNames from 'classnames'
import { ChangeEvent, useState } from 'react'
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form'
import { INpsSurveyForm, QuestionResponse } from '../NpsSurvey'

interface IQuestionChoice {
	append: UseFieldArrayAppend<INpsSurveyForm, 'surveyResponse'>,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	id: string,
	questionIndex: number,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
	value: string,
}

/** 
 * Choice component for multiple_choice questions
 * @param id id to relate the label with the input
 * @param value actual value of the input and text of the label
 * @param questionIndex actual question in the survey flow
 * @param update method to update the array of the form through useFieldArray hook
 * @param fields method to obtain all field values of the form through useFieldArray hook
 */
function QuestionChoice({
	append,
	fields,
	id,
	questionIndex,
	update,
	value,
}: IQuestionChoice) {
	// Manage styles for checked status
	const [isChecked, setIsChecked] = useState(false)

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const dataToSet: QuestionResponse = {
			value: []
		}
		// Selected choice
		const choice = e.target.value

		// Check or uncheck the checkbox
		setIsChecked(!isChecked)

		// Array that contains all selected choices
		const actualValue = fields[questionIndex]?.value

		// At the beginning this doesn't exist
		if (!actualValue) {
			// If there are positions undefined in the array between the current question index and first index, we define them
			const missingIndexes = questionIndex - fields.length
			if (missingIndexes > 0) {
				for (let index = 0; index < missingIndexes; index++) {
					append({ value: '' })          
				}
			}
			// Set current question value
			dataToSet.value = [choice]
			return update(questionIndex, dataToSet)
		}

		// We make sure that the value is an array
		if (typeof actualValue === 'object') {
			// If the selected choice is already in the values of the array
			if (actualValue?.includes(choice)) {
				// We delete it
				dataToSet.value = actualValue.filter((value) => value !== choice)
				return update(questionIndex, dataToSet)
			}

			// If it isn't in the values of the array, we add it
			dataToSet.value = [...actualValue, choice]
			update(questionIndex, dataToSet)
		}
	}

	return (
		<div
			className={classNames(
				'relative flex cursor-pointer text-xs items-center bg-white rounded border border-solid',
				styles.questionChoice,
				isChecked ? 'border-black font-bold' : 'border-black/25'
			)}
		>
			<input
				type="checkbox"
				className='hidden cursor-pointer'
				id={id}
				name={id}
				value={value}
				onChange={onChange}
			/>
			<label
				htmlFor={id}
				className='p-[10px] rounded-[4px] w-full'
			>
				{value}
			</label>
			<Icon
				name='check'
				className={classNames(
					'absolute right-[10px] cursor-default',
					styles.check,
					isChecked ? styles.checked : ''
				)}
			/>
		</div>
	)
}

export default QuestionChoice