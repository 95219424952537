import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form'
import { INpsSurveyForm } from '../NpsSurvey'
import { MultipleSurveyQuestion } from 'posthog-js'
import QuestionChoice from '../commons/QuestionChoice'

interface IMultipleChoiceQuestion {
	append: UseFieldArrayAppend<INpsSurveyForm, 'surveyResponse'>,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	question: MultipleSurveyQuestion,
	questionIndex: number,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
}

function MultipleChoiceQuestion({
	append,
	fields,
	question,
	questionIndex,
	update,
}: IMultipleChoiceQuestion) {
	return (
		<div className='flex flex-col gap-1'>
			{question.choices.map((choice: string, index: number) => (
				<QuestionChoice
					append={append}
					fields={fields}
					id={`question_${index}`}
					key={choice}
					questionIndex={questionIndex}
					update={update}
					value={choice}
				/>
			))}
		</div>
	)
}

export default MultipleChoiceQuestion