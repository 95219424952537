import React from 'react'
import { IPatient, IShortcut, chat } from '@umahealth/entities'
import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { firestore } from '@/config/DBConnection'
import { IconsNames, Loader } from '@umahealth/occipital'
import useContextUid from '@/services/firebase/useContextUid'
import useAssignedChatAttAppointment from '@/services/hooks/useAssignedChatAttAppointment'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import { isPFA } from '@/utils/validations/ValidateCoverage'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'

interface IChatAttShortcut {
	shortcut: IShortcut
	user: IPatient|null
	client: IClientState
	chatAtt: chat|null
}



function ChatAttShortcut({shortcut, client, chatAtt} : IChatAttShortcut) {
	let chatAttUrl : keyof typeof getChatAttUrl = 'default'
	const router = useRouter()
	const uid = useContextUid()
	const track = useTrack()
	
	const assignedChatAttAppointment = useAssignedChatAttAppointment(
		firestore,
		uid??'NO', 
		process.env.NEXT_PUBLIC_COUNTRY, 
		chatAtt,
		{}, 
		{
			enabled: !!uid
		}
	)

	const getChatAttUrl = {
		queue: 'chatAtt/queue',
		default: 'chatAtt/onboarding',
		call: 'chatAtt/attention',
	}

	if(isPFA(client)){ 
		return <></>
	}

	/** La llamada esta pronta a estar activa */
	if (assignedChatAttAppointment.data && ['ASSIGN'].includes(assignedChatAttAppointment.data.state))
	{
		chatAttUrl = 'queue'
	}

	/** La llamada esta activa */
	if (assignedChatAttAppointment.data && ['ATT'].includes(assignedChatAttAppointment.data.state))
	{
		chatAttUrl = 'call'
	}

	if (assignedChatAttAppointment.isLoading) {
		return <Loader size={'size-6'} color="stroke-primary" />
	}

	function redirectToModule(redirectRoute:string) {
		track.mutate(
			{ eventInput: `[Home] Clic en módulo ${redirectRoute?.split('/')?.[0]}` }
		)
		router.push(redirectRoute)
	}

	/** A la sala de espera */
	if (chatAttUrl === 'queue'){
		return (
			<ButtonService
				id={shortcut.field}
				isNew={shortcut.new ? true : undefined} 
				name={'briefcaseMedical'} 
				text={'Sala de espera'} 
				action={()=> redirectToModule(getChatAttUrl[chatAttUrl])}
				notifications={chatAtt?.unreadMessagesPatient}
				highlight
			/>
		)
	}

	/** Te redirecciono directo a la llamada */
	if (chatAttUrl === 'call'){
		return (
			<ButtonService
				id={shortcut.field}
				isNew={shortcut.new ? true : undefined} 
				name='play' 
				text={'Consulta por chat'} 
				action={()=> redirectToModule(getChatAttUrl[chatAttUrl])}
				notifications={chatAtt?.unreadMessagesPatient}
				highlight
			/>
		)
	}

	return (
		<ButtonService
			id={shortcut.field}
			isNew={shortcut.new ? true : undefined} 
			name={shortcut.icon as IconsNames} 
			text={shortcut.text} 
			action={()=> redirectToModule(getChatAttUrl[chatAttUrl??'default'])}
		/>
	)


	
}

export default ChatAttShortcut