import { useFirestoreDocumentData, UseFirestoreHookOptions } from '@react-query-firebase/firestore'
import { countries, IGuardiaAppointment } from '@umahealth/entities'
import { FirestoreKeys } from '@umahealth/fe-firebase'
import { doc, DocumentReference, Firestore, FirestoreError, SnapshotOptions } from 'firebase/firestore'
import { QueryKey, UseQueryOptions } from 'react-query'

type QueryOptions = Omit<UseQueryOptions<IGuardiaAppointment|undefined, FirestoreError, IGuardiaAppointment|undefined, QueryKey>, 'queryFn'>

interface IOnlineDoctorAppointmentData {
	assignation_id: string,
	country: countries,
	type?: 'onlinedoctor' | 'chatAtt'
}

/**
 * Retorna los servicios activos del usuario
 *
 */
function useOnlineDoctorAppointment(firestore: Firestore, appointmentdata: IOnlineDoctorAppointmentData, hookOptions?: UseFirestoreHookOptions & SnapshotOptions,  queryOptions?: QueryOptions){
	
	const countryPath : Record<countries,string>= {
		'AR' : 'assignations/online_clinica_medica/bag',
		'MX' : 'assignations/bag/MX',
		'VE' : 'assignations/bag/VE',
		'CO' : 'assignations/bag/CO'
	}

	const appointmentCountry = appointmentdata.country || process.env.NEXT_PUBLIC_COUNTRY
	
	const appointmentRef = doc(firestore, `${(appointmentdata.type != 'chatAtt' )
		? `${countryPath[appointmentCountry]}/${appointmentdata.assignation_id}` 
		: `assignations/chatAtt/${appointmentCountry}/${appointmentdata.assignation_id}`}`) as DocumentReference<IGuardiaAppointment>

	return useFirestoreDocumentData(
		FirestoreKeys.Appointments( 
			{ name: 'onlinedoctorAppointment', 
				path: `${(appointmentdata.type != 'chatAtt' )
					? `${countryPath[appointmentCountry]}/${appointmentdata.assignation_id}` 
					: `assignations/chatAtt/${appointmentCountry}/${appointmentdata.assignation_id}`}`
			}, [appointmentdata.assignation_id, appointmentCountry]),
		appointmentRef,
		hookOptions,
		queryOptions
	)
}

export default useOnlineDoctorAppointment