'use client'
import React, { ReactNode, useEffect, useState } from 'react'
import { firestore } from '@/config/DBConnection'
import { createContext } from 'react'
import { DocumentReference, doc, onSnapshot } from 'firebase/firestore'
import { chat } from '@umahealth/entities'

export const chatAttContext = createContext<chat | null>(null)

function ProviderChatAtt({ children, uid }: { children: ReactNode, uid:string|undefined }) {

	const [chatAtt, setChatAtt] = useState<chat|null>(null)

	useEffect(() => {
		if (uid) {
			const activeCallRef = doc(
				firestore,
				`user/${uid}/activeServices/onlineChat`
			) as DocumentReference<chat>

			const subscription = onSnapshot(activeCallRef, (chatAttQuery) => {
				const chatAttData = chatAttQuery.data()
				if (chatAttData) {
					setChatAtt({
						...chatAttData,
					})
				}
			})

			return () => {
				if (typeof subscription === 'function') {
					subscription()
				}
			}
		}
	}, [onSnapshot, uid, firestore])

	return (
		<chatAttContext.Provider value={chatAtt}>
			{children}
		</chatAttContext.Provider>
	)
}

export default ProviderChatAtt
