
import { UseQueryOptions, useQuery } from 'react-query'
import axios from 'axios'

type Queryoptions = Omit<UseQueryOptions<string, unknown, string, string[]>, 'queryKey' | 'queryFn'>
const apiPath = `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/api`
export const getPatientId = async (uid: string):Promise<string> => {
	const req = {
		resourceType: 'Patient',
		filters: `identifier=${uid}`
	}

	const res = await axios.post(`${apiPath}/fhir/findResources`, req)
	return res.data[0].resource.id
}
export const useGetPatientId = (firestoreUid: string, options?: Queryoptions) => {
	return useQuery(
		['FhirPatientId', firestoreUid],
		async () => {
			try {
				const res = await getPatientId(firestoreUid)
				return res
			} catch (error) {
				throw new Error(`${error}`)
			}
		},
		options
	)
}
