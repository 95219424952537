import { Icon } from '@umahealth/occipital/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

const NotificationCard = () => {
	const [show, setShow] = useState(!!localStorage.getItem('dependantInvitationSent'))
	const sent = localStorage.getItem('dependantInvitationSent') === 'true' ? true : false
  
	const onClose = () => {
		localStorage.removeItem('dependantInvitationSent')
		setShow(false)
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			onClose()
		}, 6000)

		return () => clearTimeout(timeOutId)
	}, [])

	return (
		<>
			{show ? (
				<div className='fixed bottom-6 bg-transparent w-full max-w-[600px] h-[57px] flex justify-center z-[51]' id='notification-card'>
					<div className='flex absolute w-[90%]'>
						<div className={classNames('absolute rounded-xl h-full w-6 -left-1 z-50', sent ? 'bg-success-1' : 'bg-error')} />
						<div className={classNames(
							'rounded-lg text-grey-1 text-xs flex justify-between items-center w-full h-full shadow-[0_4px_8px_3px_rgba(0,0,0,0.15)] z-[51]',
							sent ? 'bg-[#E6F9F4]' : 'bg-[#FCECEE]',
						)}>
							<div className='flex items-center gap-3 p-4 pr-0'>
								<Icon name='sendPlane2' size='size-6' visibility='visible' color={sent ? 'text-success-1' : 'text-error'} />
								<p>
									{sent ? 'Invitación enviada con éxito.' : 'Ocurrió un error al enviar la invitación.'}
								</p>
							</div>
							<button type='button' onClick={onClose} className='p-4'>
								<Icon name='close' size='size-4' visibility='visible' color={sent ? 'text-success-1' : 'text-error'} />
							</button>
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}

export default NotificationCard