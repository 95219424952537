import { useFirestoreDocumentData, UseFirestoreHookOptions } from '@react-query-firebase/firestore'
import { chat, countries, IAppointment } from '@umahealth/entities'
import { doc, DocumentReference, Firestore, FirestoreError } from 'firebase/firestore'
import { QueryKey, UseQueryOptions, UseQueryResult } from 'react-query'

type QueryOptions = Omit<UseQueryOptions<IAppointment | undefined, FirestoreError, IAppointment | undefined, QueryKey>, 'queryFn'>


/**
 * useAssignedChatAttAppointment busca en la DB el único appointment de guardia activo que debería tener el paciente.
 * En caso de tener más de uno, traerá el último.
 * Si se intenta utilizar con un paciente que no tiene medicalRecords, quedará con isIdle='true' pero no saldrá de eso.
 * 
 * @param firestore
 * @param uid
 * @param country
 * @param options suscribe: boolean, permite subscripirte y recibir cambios en tiempo real
 * @param queryOptions
 * @param assignation
 * @returns UseQueryResult<IAppointment | undefined, FirestoreError>
 */
function useAssignedChatAttAppointment(firestore: Firestore, uid: string, country: countries, chatAtt: chat|null, options?: UseFirestoreHookOptions, queryOptions?: QueryOptions): UseQueryResult<IAppointment | undefined, FirestoreError> {
	const path = `assignations/chatAtt/${country}/`

	let appointmentRef!: DocumentReference<IAppointment>

	if (chatAtt?.assignation_id) {
		appointmentRef = doc(firestore, path, chatAtt?.assignation_id) as DocumentReference<IAppointment>
	}

	const appointment = useFirestoreDocumentData<IAppointment>(
		['assignedChatAttAppointment', country, uid, chatAtt?.assignation_id],
		appointmentRef,
		options,
		{
			...queryOptions,
			enabled: !!chatAtt?.assignation_id && queryOptions?.enabled,
		}
	)

	// 🥳 Salió todo bien pero el dato igual podría venir vacío, ten precaución
	return appointment
}

export default useAssignedChatAttAppointment
