import { auth } from '@/config/DBConnection'

/**
 * Obtiene el ```uid``` de Firebase.
 * Es un HOOK, usar con precaución, además, lo obtiene del context, por tanto sólo sirve en 
 * rutas privadas
 * 
 * @returns {string} {@link https://firebase.google.com/docs/reference/js/v8/firebase.User#uid Uid}
 */
function useContextUid(): string|undefined {
	return auth?.currentUser?.uid

}

export default useContextUid