import { RatingSurveyQuestion, SurveyAppearance } from 'posthog-js'
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form'
import { INpsSurveyForm } from '../NpsSurvey'
import { scoreArray } from '../utils/npsScore'
import ScoreButton from '../commons/ScoreButton'

interface IRatingQuestion {
	appearance: SurveyAppearance | null,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	question: RatingSurveyQuestion,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
}

function RatingQuestion({
	appearance,
	fields,
	question,
	update,
}: IRatingQuestion) {
	return (
		<>
			<div className='grid grid-cols-11 rounded-md overflow-hidden border-[1.5px] border-solid border-[#c9c6c6]'>
				{scoreArray.map((score) => (
					<ScoreButton
						appearance={appearance}
						fields={fields}
						key={`key_${score}`}
						score={score}
						update={update}
					/>
				))}
			</div>
			<div className='flex justify-between mt-[6px] opacity-60 text-[11px]'>
				<span>
					{question.lowerBoundLabel}
				</span>
				<span>
					{question.upperBoundLabel}
				</span>
			</div>
		</>
	)
}

export default RatingQuestion