'use client'
import React, { useCallback, useState } from 'react'
import ManageSubscriptionDrawer from './ManageSubscriptionDrawer' 
import { ISubscriptionWithId } from '@umahealth/fe-firebase/src/Firestore-Query/Subscription/useSuscription'

type SubscriptionDrawerManagerProps = {
	token: string | undefined;
	pendingSubscriptions: ISubscriptionWithId[];
}

const SubscriptionDrawerManager = ({ token, pendingSubscriptions }: SubscriptionDrawerManagerProps) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0)

	const handleNextSubscription = useCallback(() => {
		if (currentIndex < pendingSubscriptions.length - 1) {
		  setCurrentIndex(prevIndex => prevIndex + 1)
		}
	  }, [currentIndex, pendingSubscriptions.length])
	
	  if (currentIndex >= pendingSubscriptions.length) {
		return null
	  }

	return (
		<ManageSubscriptionDrawer 
			key={pendingSubscriptions[currentIndex].id}
			token={token}
			subscription={pendingSubscriptions[currentIndex]}
			onNext={handleNextSubscription}
		/>
	)
}

export default SubscriptionDrawerManager