import React from 'react'
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
} from '@umahealth/occipital/client'

/**
 * ReusableDrawer Component
 *
 * Este componente crea un drawer reutilizable que puede ser utilizado en distintas partes de la aplicación.
 * Debe haber un botón externo que controle la apertura del drawer.
 *
 * @component
 * @example
 * const title = "Título del Drawer"
 * const description = <p>Descripción del contenido del drawer.</p>
 * const footerActions = <Button action={() => setDrawerOpen(false)}>Continuar</Button>
 *
 * const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
 *
 * return (
 *   <>
 *     <Button onClick={() => setDrawerOpen(true)}>Abrir Drawer</Button>
 *     <ReusableDrawer
 *       open={drawerOpen}
 *       title={title}
 *       description={description}
 *       footerActions={footerActions}
 *       onClose={() => setDrawerOpen(false)}
 *     />
 *   </>
 * )
 *
 * @param {Object} props - Propiedades del componente
 * @param {boolean} props.open - Indica si el drawer está abierto o cerrado
 * @param {string} props.title - Título del drawer
 * @param {React.ReactNode} props.description - Contenido descriptivo del drawer
 * @param {React.ReactNode} props.footerActions - Acciones del footer del drawer
 * @param {function} [props.onClose] - Función que se llama cuando el drawer se cierra
 * @returns {React.ReactElement} El componente Drawer
 */

interface IReusableDrawer {
	open: boolean
	title: string
	description: React.ReactNode
	footerActions?: React.ReactNode
	onClose?: () => void
	dismissible?: boolean
}

const ReusableDrawer = ({
	open,
	title,
	description,
	footerActions,
	dismissible = true,
	onClose = () => {},
}: IReusableDrawer) => {
	return (
		<Drawer open={open} dismissible={dismissible} onClose={onClose}>
			<DrawerContent className="max-w-[600px] mx-auto">
				<DrawerHeader>
					<DrawerTitle className="text-center">{title}</DrawerTitle>
				</DrawerHeader>
				<DrawerDescription className="px-4">
					{description}
				</DrawerDescription>
				<DrawerFooter>{footerActions}</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)
}

export default ReusableDrawer
