'use client'
import React from 'react'
import { IMedicalRecord } from '@umahealth/entities'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { Loader } from '@umahealth/occipital'
import { usePendingMedicalRecordsSpecialist } from '@umahealth/fe-firebase'
import makeSpecialistPath from '@/components/Especialista/Utils/makeSpecialistPath'
import { firestore } from '@/config/DBConnection'
import ServiceButton from '@/storybook/components/sharedComponents/ServiceButton/ServiceButton'

interface ExtendedMedicalRecord extends IMedicalRecord {
	isSooner: boolean,
	timeLeft: number
}

function SpecialistShortcut({ uid } : { uid : string}) {
	const now = moment()
	
	const pendingMedicalRecords = usePendingMedicalRecordsSpecialist<ExtendedMedicalRecord>(
		firestore,
		uid,
		{},
		{
			enabled: !!uid,
			select: (medicalRecords) => {
				if (medicalRecords.length === 0 || medicalRecords === undefined){
					return []
				}
				const medicalRecordsWithTimeleft = medicalRecords.map((medicalRecord) => {
					const dt_assignation = medicalRecord.timestamps.dt_assignation as Timestamp
					const dt_assignation_moment = moment(dt_assignation.toDate())
					const diff = moment.duration(dt_assignation_moment.diff(now))
					const isSooner = Math.abs(diff.asMinutes()) < 30
					const extendedMedicalRecord : ExtendedMedicalRecord =  {
						...medicalRecord,
						isSooner: isSooner,
						timeLeft: Math.abs(diff.asMinutes())
					}
					return extendedMedicalRecord
				})

				return medicalRecordsWithTimeleft.sort( medicalRecord => medicalRecord.timeLeft)
			}})


	const MostSoonerAppointment = pendingMedicalRecords.data?.find(medicalRecord => Math.min(medicalRecord.timeLeft))
	// Si el appointment tiene el turno dentro de 30 minutos, lo llevo a queue
	const isQueue = MostSoonerAppointment && MostSoonerAppointment.isSooner

	if (isQueue)
	{
		return <ServiceButton
			className="mr-3" 
			name='briefcaseMedical'
			href={`marketplace/queue/${MostSoonerAppointment.assignation_id}`}
		>
			Sala de espera
		</ServiceButton>
	}

	if (!isQueue && pendingMedicalRecords?.data?.length) {
		return <ServiceButton
			className="mr-3" 
			name='BiCalendar'
			href={makeSpecialistPath('pending', {
				dependant: false,
				patientUid: uid??'NO',
				type: 'online',
			})}
		>
			Agenda tu cita
		</ServiceButton>
	}

	if (pendingMedicalRecords.isLoading || pendingMedicalRecords.isIdle){
		return (
			<div className={'w-[107px] h-[77px] border border-solid border-grey-5 text-primary rounded-md flex items-center flex-col align-middle content-center justify-center py-3 px-4 mr-3'}>
				<Loader size={'size-5'} color='stroke-primary'/>
			</div>
		)
	}

	return 	<ServiceButton className="mr-3" href="/pharmacovigilance/select" name='BiCalendar'>
					Agenda tu cita
	</ServiceButton>
}

export default SpecialistShortcut