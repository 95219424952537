import { useFirestoreDocumentData, UseFirestoreHookOptions } from '@react-query-firebase/firestore'
import { call } from '@umahealth/entities'
import { FirestoreKeys } from '@umahealth/fe-firebase'
import { doc, DocumentReference, Firestore, FirestoreError, SnapshotOptions } from 'firebase/firestore'
import { QueryKey, UseQueryOptions } from 'react-query'

type QueryOptions = Omit<UseQueryOptions<call|undefined, FirestoreError, call|undefined, QueryKey>, 'queryFn'>
/**
 * Retorna los servicios activos del usuario
 *
 */
function useActiveServices(firestore: Firestore, uid: string, hookOptions?: UseFirestoreHookOptions & SnapshotOptions,  queryOptions?: QueryOptions){
	const activeServicesRef = doc(firestore,`user/${uid}/activeServices/onlineCall`) as DocumentReference<call>

	return useFirestoreDocumentData(
		FirestoreKeys.User( 
			{ name: 'activeServices', 
				path: `user/${uid}/activeServices`
			}, [uid]),
		activeServicesRef,
		hookOptions,
		queryOptions
	)
}

export default useActiveServices