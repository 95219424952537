export const disableChatbot = () => {
	const navLang = window ? window.navigator.language : 'en-US'

	const message = {
		iconButton: navLang === 'en-US' ? 'Button to launch messaging window' : 'Botón para iniciar la ventana de mensajería',
		queryButton: navLang === 'en-US' ? 'Message from company' : 'Mensaje de la compañía',
		closeButton: navLang === 'en-US' ? 'Close message' : 'Cerrar mensaje',
		closeMessages: navLang === 'en-US' ? 'Number of unread messages' : 'Número de mensajes no leídos'
	}

	const button = document?.querySelector(
		`[title="${message.iconButton}"]`
	)
	const otherButton = document?.querySelector(
		`[title="${message.queryButton}"]`
	)
	const closeButton = document.querySelector(
		`[title="${message.closeButton}"]`
	)

	const closeMessages = document.querySelector(
		`[title="${message.closeMessages}"]`
	)

	if (button){
		button.remove()
	}
	if (otherButton){
		otherButton.remove()
	}
	if (closeButton) {
		closeButton.remove()
	}

	if (closeMessages) {
		closeMessages.remove()
	}
	
}
