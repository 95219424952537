import React from 'react'
import { IHistorySections } from '@umahealth/entities'
import { DocumentData, FirestoreError } from 'firebase/firestore'
import { Icon, IconsNames } from 'occipital-new'
import type { UseQueryResult } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { Text } from 'occipital-new'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import style from '../styles/homepage.module.scss'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import Link from 'next/link'
import { trackEventHistorySection } from '@/events/homeEvents'

interface EventElement<TData> {
	queryEvent : UseQueryResult<TData[], FirestoreError>
	section : IHistorySections
}

function EventElement<TData = DocumentData>({ queryEvent, section } : EventElement<TData>) : JSX.Element|null{
	const notifications = useAppSelector(state => state.front.notificationsHome)
	const track = useTrack()

	if (queryEvent.isLoading){
		return (
			<div className="flex bg-grey-6 py-5 px-4 border border-solid border-grey-5 rounded-b-md" >
				<Skeleton width={20} height={20} circle/>
				<Skeleton className='historyCard__event' width='136px'/>
			</div>
		)
	}

	if (queryEvent.isSuccess && queryEvent.data.length > 0){
		return <React.Fragment key={section.title}>
			<Link href={section.link} className=" w-full flex bg-grey-6 py-5 px-4 border border-solid border-grey-5 rounded-b-md" id={section.title} onClick={() => {
				track.mutate({
					eventInput: `[Home] Clic en historial ${section.link}`
				})
				trackEventHistorySection(section.title)
			}}>
				<div className={style.historyCard}>
					<Icon
						color='primary'
						name={`${section.icon as IconsNames}`}
						size={'m'}
						aria-hidden
					/>
					<h2 className="historyCard__event">{section.title}</h2>
				</div>
				{section.title === 'Recetas' && notifications.prescriptions.new && 
					<Text size='xxs' weight='semibold' color='background-light' tag='span' className={style.notifications}>{notifications.prescriptions.quantity} {notifications.prescriptions.quantity === 1 ? 'receta nueva' : 'recetas nuevas'}</Text>
				}
				{section.title === 'Consultas' && notifications.consultations.new && 
					<Text size='xxs' weight='semibold' color='background-light' tag='span' className={style.notifications}>{notifications.consultations.quantity} {notifications.consultations.quantity === 1 ? 'consulta nueva' : 'consultas nuevas'}</Text>
				}
			</Link>
		</React.Fragment>
	}

	return null
}

export default EventElement