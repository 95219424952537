import { deletePatientSubscriptionPending } from '@/app/services/clientServices/deletePatientSubscriptionPending'
import { useMutation } from '@tanstack/react-query'

type DeleteSubscriptionParams = {
	token: string | undefined
	documentId: string
}

export const useDeletePendingSubscription = () => {
	const mutation = useMutation<void, Error, DeleteSubscriptionParams>({
		mutationFn: deletePatientSubscriptionPending,
		onSuccess: () => {
			window.location.reload()
		},
	})

	const deleteSubscription = async (params: DeleteSubscriptionParams) => {
		await mutation.mutateAsync(params)
	}

	return {
		deleteSubscription,
		isLoading: mutation.isPending,
		isError: mutation.isError,
		error: mutation.error,
		reset: mutation.reset,
	}
}
