import { track } from '@amplitude/analytics-browser'
import { useMutation } from 'react-query'
import { EventOptions } from '@amplitude/analytics-types'

export interface TrackMutation {
	eventInput: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	eventProperties?: Record<string,any>
	eventOptions?: EventOptions
}

/**
 * 
 * Función que nos ayuda a registrar un evento en Amplitude
 * Por defecto no se registran, esto es antintuitivo pero no queremos pasar la cuota
 */
function useTrack(enable : boolean = false ){
	return useMutation(['Amplitude-Query','track'], async({eventInput, eventProperties, eventOptions} : TrackMutation) => {
		// TODO: Esto es temporal hasta que tengamos las 3 instancias, por ahora sólo se logea en prod
		if (process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === 'uma-v2' && enable){
			track(eventInput,eventProperties,eventOptions)
		}
	})
}

export default useTrack