'use client'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import { Icon } from '@umahealth/occipital/client'

export default function Badge({className, children, open : openDefault = true, closeButton = true} : {className?:string, children, open?: boolean, closeButton?: boolean}){

	const [open, setOpen] = useState(openDefault)

	if (open === false){
		return null
	}

	return <div  className={cn('relative w-full px-9 py-4 bg-primary border-0 flex rounded-md text-left',className)}>
		{children}
		{closeButton &&
			<button onClick={() => {
				setOpen(!open)
			}}>
				<Icon className='absolute right-5 top-5' name='close'/>
			</button>
		}
	</div>
}