import { UseQueryOptions, useQuery } from 'react-query'
import { BundleEntry } from '@smile-cdr/fhirts/dist/FHIR-R3'
import axios from 'axios'

type Queryoptions = Omit<UseQueryOptions<BundleEntry[], unknown, BundleEntry[] , string[]>, 'queryKey' | 'queryFn'>
const apiPath = `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/api`
export const searchFhirResource = async (resourceType: string, filters: string) => {
	try {
		const req = {
			resourceType: resourceType,
			filters: filters,
		}

		const res = await axios.post(`${apiPath}/fhir/findResources`, req)
		return res.data
	} catch(err) {
		console.error(err)
	}
}
export const useAdvancedSearchFhirResource = (
	resourceType: string,
	filters: string,
	options?: Queryoptions,
) => {
	return useQuery(
		['FhirSearch', resourceType, filters],
		async () => {
			try {
				const res = await searchFhirResource(resourceType, filters)
				return res
			} catch (error) {
				console.error(error)
				throw new Error(error as string)
			}
		},
		options,
	)
}
